<template>
  <Modal v-show="isModalVisible" @close="closeModal">
    <template v-slot:header>
      Redigera butiksgrupp <Spinner v-if="isLoading" />
    </template>
    <template v-slot:body>
      <form @submit.prevent="update">
        <div class="form-group">
          <label>Rubrik</label>
          <input v-model="name" class="form-control" required />
        </div>
        <div class="form-group">
          <label>Välj målgruppsartiklar</label><br />
          <button @click="showSelModal" type="button" class="btn createBtn">
            +
          </button>
          <div v-if="stores.length > 0" class="selections">
            <ul class="form-group mt-4">
              <li v-for="storeName in stores" :key="storeName" class="item">
                {{ storeName }}
              </li>
            </ul>
          </div>
          <SelectionModal
            v-bind:editChecked="{ mainGroup }"
            @finishedData="logChange"
            v-if="isSelModalVisible"
            @close="closeSelModal"
          >
            <template v-slot:header>
              <h3>Välj butiker</h3>
              <Spinner v-if="isLoading" />
            </template>
          </SelectionModal>
        </div>

        <button
          @click="closeModal()"
          type="submit"
          class="btn btn-success mt-3"
        >
          Redigera målgrupp
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import { useRouter } from 'vue-router'
import {
  getStoreTarget,
  updateStoreTarget,
  storeTargetCollection,
} from '@/firebase'
import Modal from '../Modal.vue'
import SelectionModal from './StoreTargetSelection.vue'
import Spinner from '../misc/Spinner.vue'

export default {
  components: {
    Modal,
    SelectionModal,
    Spinner,
  },
  data() {
    return {
      isModalVisible: false,
      isSelModalVisible: false,
      articleList: [],
      mainGroup: [],
      isLoading: true,
      articles: null,
      name: '',
      stores: [],
      showMessage: false,
      alertMessage: { message: '', error: false },
      router: useRouter(),
    }
  },
  async created() {
    this.isLoading = true
    try {
      await storeTargetCollection
        .orderBy('created', 'desc')
        .onSnapshot(async (documents) => {
          this.articles = []
          documents.forEach((doc) => {
            this.articles.push({
              id: doc.id,
              ...doc.data(),
            })
          })
        })
      this.alertMessage = {
        message: 'Loaded storeTargets successfully!',
        error: false,
      }
      await this.setMessage()
      this.isLoading = false
    } catch (error) {
      console.log(error)
      this.alertMessage = {
        message: 'Failed to load in the storeTargets!',
        error: true,
      }
      await this.setMessage()
      return (this.isLoading = false)
    }
  },
  methods: {
    showModal(id) {
      this.isModalVisible = true
      this.identifySearch = id
      this.editOpened(id)
    },
    async editOpened(id) {
      console.log(id)
      const target = await getStoreTarget(id)
      console.log(target)
      this.name = target.name
      this.stores = target.stores
      this.isLoading = false
    },
    closeModal() {
      this.isModalVisible = false
    },
    showSelModal() {
      this.isSelModalVisible = true
      this.mainGroup = this.stores
    },
    closeSelModal() {
      this.isSelModalVisible = false
    },
    async update() {
      this.isLoading = true
      if (this.name !== '') {
        if (this.stores.length > 0) {
          const form = { name: this.name, stores: this.stores }
          await updateStoreTarget(this.identifySearch, { ...form })
          this.router.push('/storeTarget')
          this.name = ''
          this.stores = ''
          this.data = []
        } else {
          alert('Välj artikel!')
        }
      } else {
        alert('Du har inte fyllt i allt korrekt!')
      }
      this.isLoading = false
    },
    logChange(event) {
      this.mainGroup = []
      console.log('huvud: ', event.mainGroup)
      this.stores = event.mainGroup
    },
    timeConvert(time) {
      var date = new Date(time._seconds * 1000)

      var MyDateString =
        date.getFullYear() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)

      return MyDateString
    },
    async setMessage() {
      this.showMessage = true
      return setTimeout(() => {
        return (this.showMessage = false)
      }, 2000)
    },
  },
}
</script>

<style scoped>
.form-group {
  margin: 0;
  padding: 0;
}
.createBtn {
  background: rgb(32, 183, 248);
  color: white;
}
.createBtn:hover {
  color: white;
  background: rgb(28, 161, 218);
}
.selections {
  display: flex;
}
.item {
  background-color: rgb(32, 183, 248);
  color: white;
  margin: 4px;
  padding: 10px;
  display: inline-block;
}
</style>
