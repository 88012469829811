<template>
<div>
  <NavBar />
  <div class="row p-5">
    <div class="col-md-12">
      <TargetList />
    </div>
  </div>
</div>
</template>

<script>
import TargetList from "@/components/store_targets/StoreTargetList.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "StoreTarget",
  components: { TargetList, NavBar },
  setup() {},
};
</script>
