<template>
  <div>
    <button @click="showModal" type="button" class="btn addStoresBtn">+</button>
    <Modal v-show="isModalVisible" @close="closeModal">
      <template v-slot:header> Skapa butiksgrupp </template>

      <template v-slot:body>
        <form class="formBody" @submit.prevent="onCreateTarget">
          <div class="form-group">
            <label>Rubrik</label>
            <input v-model="name" class="form-control" required />
          </div>
          <div class="form-group mt-2">
            <label>Välj målgruppsartiklar</label><br />
            <button
              @click="showSelModal"
              type="button"
              class="btn addStoresBtn"
            >
              +
            </button>
            <div v-if="stores.length > 0" class="selections">
              <ul class="form-group mt-4">
                <li v-for="storeName in stores" :key="storeName" class="item">
                  {{ storeName }}
                </li>
              </ul>
            </div>

            <SelectionModal
              :editing="tempGroup"
              @finishedData="logChange"
              @editingData="logEditing"
              v-if="isSelModalVisible"
              @close="closeSelModal"
            >
              <template v-slot:header
                ><h3>Välj huvudgrupper och artikelgrupper!</h3></template
              >
            </SelectionModal>
          </div>

          <div class="form-group-end">
            <button
              @click="closeModal()"
              type="submit"
              class="btn createStoreTargetBtn mt-3"
            >
              Skapa målgrupp
            </button>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import { createStoreTarget } from '@/firebase'
import Modal from '../Modal.vue'
import SelectionModal from './StoreTargetSelection.vue'
export default {
  components: {
    Modal,
    SelectionModal,
  },
  data() {
    return {
      articleList: [],
      isModalVisible: false,
      isSelModalVisible: false,
      mainGroup: [],
      underGroup: [],
      tempGroup: [],
      name: '',
      stores: '',
      articles: [],
    }
  },
  async created() {},
  watch: {
    stores(newStore) {
      this.tempGroup = newStore
    },
  },
  methods: {
    showModal() {
      this.isModalVisible = true
      this.tempGroup = this.stores
    },
    closeModal() {
      this.tempGroup = []
      this.isModalVisible = false
    },
    showSelModal() {
      this.isSelModalVisible = true
    },
    closeSelModal() {
      this.isSelModalVisible = false
    },
    logChange(event) {
      this.mainGroup = []
      this.tempGroup = []
      console.log('huvud: ', event.mainGroup)
      this.stores = event.mainGroup
    },
    logEditing(event) {
      console.log('Event: ', event)
      this.tempGroup = event
    },
    async onCreateTarget() {
      try {
        if (this.name === '') throw 'The title can not be empty!'
        if (this.stores.length === 0)
          throw 'You need to select a store to target!'
        const form = { name: this.name, stores: this.stores }
        console.log(form)
        await createStoreTarget({ ...form })
        this.name = ''
        this.stores = ''
        return console.log('Successfully created a storeTarget!')
      } catch (err) {
        return console.log(err)
      }
    },
  },
}
</script>

<style scoped>
.formBody {
  display: flex;
  height: 100% !important;
  flex-direction: column;
}
.form-group {
  margin: 0;
  padding: 0;
}
.form-group-end {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.createStoreTargetBtn {
  justify-self: flex-end;
  background: rgb(32, 183, 248);
  color: white;
}
.createStoreTargetBtn:hover {
  color: white;
  background: rgb(28, 161, 218);
}
.addStoresBtn {
  background: rgb(32, 183, 248);
  color: white;
}
.selections {
  display: flex;
}
.item {
  background-color: rgb(32, 183, 248);
  color: white;
  margin: 4px;
  padding: 10px;
  display: inline-block;
}
/* .selections p {
} */
</style>
