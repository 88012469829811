<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <slot name="header"> Loading Title... </slot>
          <Spinner v-if="isLoading" />
          <button type="button" class="btn-close" @click="close">x</button>
        </header>

        <section class="modal-body">
          <div class="w-100 mb-2">
            <h4>Butiker</h4>
            <!-- <input
              style="margin-right: 5px"
              type="text"
              v-model="search"
              placeholder="Sök"
            /> -->
          </div>
          <div
            class="btn-group"
            role="group"
            aria-label="Basic checkbox toggle button group"
            style=""
          >
            <div v-for="{ id, name } in findMain" :key="id" style="margin: 5px">
              <input
                type="checkbox"
                class="btn-check"
                :id="id"
                autocomplete="off"
                :value="name"
                v-model="checkedStores"
              />
              <label class="btn btn-outline-primary" :for="id">{{
                name
              }}</label>
            </div>
          </div>
          <div class="stk btmButton">
            <button
              type="button"
              class="btn-confirmation"
              @click="selectionFinished"
            >
              Skicka
            </button>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { storesCollection } from '@/firebase'
import Spinner from '../misc/Spinner.vue'

export default {
  name: 'StoreSelectionModal',
  components: { Spinner },
  props: ['editChecked', 'editing'],
  data() {
    return {
      checkedStores: [],
      mainArticleList: [],
      isLoading: true,
      groups: null,
      showMessage: false,
      alertMessage: { message: '', error: false },
    }
  },
  async created() {
    this.isLoading = true
    try {
      await storesCollection.onSnapshot(async (documents) => {
        this.groups = []
        documents.forEach((doc) => {
          this.groups.push({
            id: doc.id,
            ...doc.data(),
          })
        })
      })
      this.isLoading = false
    } catch (error) {
      console.log(error)
      return (this.isLoading = false)
    }
  },
  computed: {
    findMain() {
      return this.mainArticleList.map((mainName) => {
        this.isLoading = true
        var singleObj = {}
        singleObj['id'] = mainName.id
        singleObj['name'] = mainName.name
        this.isLoading = false
        return singleObj
      })
    },
  },
  methods: {
    close() {
      console.log('DAtaEvent: ', this.checkedStores)
      this.$emit('editingData', this.checkedStores)
      this.$emit('close')
    },
    fileRead(xlsxfile) {
      xlsxfile.forEach((element) => {
        var mainObj = {}
        mainObj.id = element.id
        mainObj.name = element.storeName
        this.mainArticleList.push(mainObj)
      })
      this.isLoading = false
      console.log(this.mainArticleList)
    },
    selectionFinished() {
      const finalObj = {}
      finalObj['mainGroup'] = this.checkedStores
      this.$emit('finishedData', finalObj)
      this.$emit('close')
    },
  },
  watch: {
    checkedStores: {
      handler(newValues, previousValues) {
        console.log('new: ', newValues)
        console.log('previsous: ', previousValues)

        // var last = newValues[newValues.length - 1];

        if (newValues.length - previousValues.length < 0) {
          console.log(this.checkedStores)
        } else {
          console.log(this.checkedStores)
        }
      },
    },
    groups(newGroup) {
      console.log('NG: ', newGroup)
      this.fileRead(newGroup)
    },
  },
  mounted() {
    this.isLoading = false
    if (this.editChecked) {
      this.checkedStores = this.editChecked.mainGroup
    }
    if (this.editing) {
      console.log('editing: ', this.editing)
      this.checkedStores = this.editing
    }
  },
}
</script>

<style scoped>
.stk {
  position: sticky;
}
.btmButton {
  bottom: 2rem;
  align-self: flex-end;
}
.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
}
.storeLabel {
  color: black;
  font-size: 9pt;
  position: relative;
  background: white;
  border: 1pt solid blue;
  border-radius: 3px;
  top: -10px;
  right: -10px;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  padding: 20px;
  position: relative;
  width: 60%;
  height: 80%;
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: rgb(32, 183, 248);
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: rgb(73, 73, 73);
  background: transparent;
}
.btn-confirmation {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 1rem 3rem;
  cursor: pointer;
  font-weight: bold;
  color: white;
  background: rgb(32, 183, 248);
  border: 1px solid rgb(32, 183, 248);
  border-radius: 2px;
}

.btn-green {
  color: white;
  background: rgb(32, 183, 248);
  border: 1px solid rgb(32, 183, 248);
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
