<template>
  <div>
    <div style="height: 100px">
      <div
        v-show="showMessage"
        class="alert"
        :class="alertMessage.error ? 'alert-danger' : 'alert-success'"
        role="alert"
      >
        {{ alertMessage.message }}
      </div>
    </div>
    <div class="w-100 bg-white mt-5 rounded">
      <nav class="navbar navbar-light">
        <div class="container-fluid">
          <input
            style="margin-right: 5px"
            type="text"
            v-model="search"
            placeholder="Sök"
            class="searchbar"
          />
          <div class="d-flex">
            <TargetEdit ref="editModal" />
            <Spinner v-if="isLoading" />
            <TargetCreate v-if="!isLoading" ref="createModal" />
          </div>
        </div>
      </nav>
    </div>
    <div class="card mt-1 p-2" style="min-height: 542px">
      <table class="table m-0">
        <thead>
          <tr>
            <th scope="col">Namn</th>
            <th scope="col">Butiker</th>
            <th scope="col">Alternativ</th>
            <th @click="sortedItems(sortType)" scope="col">{{ sortType }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="{ id, name, stores } in sortedTargets"
            :key="id"
            @click="onEdit($event, id)"
            class="itemrow"
          >
            <td>{{ name }}</td>
            <td class="tooltipx">
              {{ stores[0] }}
              <span v-if="stores.length > 1">...</span>
              <span class="tooltiptext">
                <span v-for="article in stores" :key="article">
                  {{ article.toString().split(':')[0] }}<br />
                </span>
              </span>
            </td>
            <td>
              <button
                style="
                  background: rgb(245, 110, 110);
                  border-color: rgb(245, 110, 110);
                "
                @click="onDelete(id)"
                type="delete"
                class="btn btn-danger"
              >
                Ta bort
              </button>
            </td>
            <td style="width: 1%"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { deleteStoreTarget, storeTargetCollection } from '@/firebase'
import TargetCreate from './StoreTargetCreate.vue'
import TargetEdit from './StoreTargetEdit.vue'
import Spinner from '../misc/Spinner.vue'

export default {
  components: {
    TargetCreate,
    TargetEdit,
    Spinner,
  },
  data() {
    return {
      search: '',
      sortType: 'DESC',
      isLoading: true,
      targets: null,
      sortedTargets: null,
      showMessage: false,
      alertMessage: { message: '', error: false },
    }
  },
  async created() {
    this.isLoading = true
    try {
      await storeTargetCollection
        .orderBy('created', 'desc')
        .onSnapshot(async (documents) => {
          this.sortedTargets = []
          documents.forEach((doc) => {
            this.sortedTargets.push({
              id: doc.id,
              ...doc.data(),
            })
          })
        })
      this.alertMessage = {
        message: 'Loaded storeTargets successfully!',
        error: false,
      }
      await this.setMessage()
      this.isLoading = false
    } catch (error) {
      console.log(error)
      this.alertMessage = {
        message: 'Failed to load in the storeTargets!',
        error: true,
      }
      await this.setMessage()
      return (this.isLoading = false)
    }
  },
  methods: {
    async sortedItems(sortType) {
      if (sortType === 'ASC') {
        this.sortType = 'DESC'
        this.targets = await this.targets.sort((a, b) => {
          return a.created - b.created
        })
      } else {
        this.sortType = 'ASC'
        this.targets = this.targets.sort((a, b) => {
          return b.created - a.created
        })
      }
    },
    timeConvert(time) {
      var date = new Date(time._seconds * 1000)

      var myDateString =
        date.getFullYear() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)
      return myDateString
    },
    onEdit(event, id) {
      if (
        event.target.tagName !== 'INPUT' &&
        event.target.tagName !== 'BUTTON'
      ) {
        this.$refs.editModal.showModal(id)
      }
    },
    async onDelete(id) {
      this.isLoading = true
      const delResult = await deleteStoreTarget(id)
      console.log('del', delResult)
      this.isLoading = false
    },
    async setMessage() {
      this.showMessage = true
      return setTimeout(() => {
        return (this.showMessage = false)
      }, 2000)
    },
  },

  computed: {
    // filteredList() {
    //   // return this.targets.filter((target) => {
    //   //   const resultName = target.name
    //   //     .toLowerCase()
    //   //     .includes(this.search.toLowerCase());
    //   //   const resultGroup = "Inte tillagd!";
    //   //   if (target.stores.length > 0) {
    //   //     this.resultGroup = target.stores[0]
    //   //       .toLowerCase()
    //   //       .includes(this.search.toLowerCase());
    //   //   }
    //   //   if (resultName) {
    //   //     this.isLoading = false;
    //   //     return resultName;
    //   //   } else {
    //   //     this.isLoading = false;
    //   //     return resultGroup;
    //   //   }
    //   // });
    // },
  },
  watch: {
    search(newValue) {
      if (newValue.length > 0) {
        this.sortedTargets = this.targets.filter((target) => {
          console.log(newValue)
          console.log(target)
          const result = target.name
            .toLowerCase()
            .includes(newValue.toLowerCase())
          if (result) return result
          // const resultName = target.name
          //   .toLowerCase()
          //   .includes(this.search.toLowerCase());
          // const resultGroup = "Inte tillagd!";
          // if (target.stores.length > 0) {
          //   this.resultGroup = target.stores[0]
          //     .toLowerCase()
          //     .includes(this.search.toLowerCase());
          // }
          // if (resultName) {
          //   this.isLoading = false;
          //   return resultName;
          // } else {
          //   this.isLoading = false;
          //   return resultGroup;
          // }
        })
      } else {
        this.sortedTargets = this.targets
      }
    },
  },
}
</script>

<style scoped>
.icon {
  margin-right: 0.5rem;
}
.icon .fa-trash-alt {
  color: #fff;
  background-color: black;
  border-radius: 50%;
  padding: 10px 12px;
}
.icon .fa-pencil-alt {
  color: #fff;
  background-color: #3786ec;
  border-radius: 50%;
  padding: 10px 10px;
}
tr.itemrow:hover {
  background-color: #f8f9fa;
}
tr {
  color: rgb(40, 40, 40);
}
.card {
  border: 0;
  background: white;
}
.searchbar {
  border: none;
}
.searchbar:focus {
  outline: none;
  border-bottom: 1px solid rgb(200, 200, 200);
  border-top: none;
  border-left: none;
  border-right: none;
}
.tooltipx {
  position: relative;
}

.tooltipx .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: rgb(40, 40, 40, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltipx:hover .tooltiptext {
  visibility: visible;
}

.alert {
  margin: 0;
  top: -20px;
}
</style>
